export const optionsArea = [
    {
        id: "510600000000",
        label: "德阳市",
        value: "510600000000",
        children: [
            {
                id: "510603000000",
                label: "旌阳区",
                value: "510603000000",
            },
            {
                id: "510623000000",
                label: "中江县",
                value: "510623000000",
            },
            {
                id: "510626000000",
                label: "德阳市罗江区",
                value: "510626000000",
            },
            {
                id: "510662000000",
                label: "德阳凯州新城",
                value: "510662000000",
            },
            {
                id: "510681000000",
                label: "广汉市",
                value: "510681000000",
            },
            {
                id: "510682000000",
                label: "什邡市",
                value: "510682000000",
            },
            {
                id: "510683000000",
                label: "绵竹市",
                value: "510683000000",
            },
            {
                id: "510699000000",
                label: "德阳经济技术开发区",
                value: "510699000000",
            },
        ],
    },
];
export const optionsDept = [
    {
        id: "002003021002",
        label: "德阳市级部门",
        value: "002003021002",
        children: [
            {
                id: "3947307944844120064",
                label: "德阳是财政局监督检查分局",
                value: "3947307944844120064",
            },
            {
                id: "3961518842592808960",
                label: "食品流通与特殊食品安全监督管理科",
                value: "3961518842592808960",
            },
            {
                id: "3961518645268369408",
                label: "食品生产安全监督管理科",
                value: "3961518645268369408",
            },
            {
                id: "3961518346209644544",
                label: "食品安全协调科",
                value: "3961518346209644544",
            },
            {
                id: "3961518119331876864",
                label: "产品质量安全监督管理科",
                value: "3961518119331876864",
            },
            {
                id: "3961517852641251328",
                label: "质量发展科",
                value: "3961517852641251328",
            },
            {
                id: "3961509234789502976",
                label: "反垄断与反不正当竞争科",
                value: "3961509234789502976",
            },
            {
                id: "3959317527004237824",
                label: "德阳市职业技能鉴定指导中心",
                value: "3959317527004237824",
            },
            {
                id: "3958619376391737344",
                label: "商标与专利监管科",
                value: "3958619376391737344",
            },
            {
                id: "3958538527406608384",
                label: "产品质量安全监督管理科",
                value: "3958538527406608384",
            },
            {
                id: "3956002044850786304",
                label: "德阳市社会保险局",
                value: "3956002044850786304",
            },
            {
                id: "3955968800327389184",
                label: "会员部",
                value: "3955968800327389184",
            },
            {
                id: "3953451614048407552",
                label: "养老保险科",
                value: "3953451614048407552",
            },
            {
                id: "395",
                label: "德阳市卫生健康委员会",
                value: "395",
            },
            {
                id: "3947308552427589632",
                label: "监督检查分局",
                value: "3947308552427589632",
            },
            {
                id: "3961519069471887360",
                label: "餐饮服务食品安全监督管理科",
                value: "3961519069471887360",
            },
            {
                id: "3935694515721654272",
                label: "德阳市市场监管局行政审批科",
                value: "3935694515721654272",
            },
            {
                id: "3921635450921603072",
                label: "德阳市医疗保障局",
                value: "3921635450921603072",
            },
            {
                id: "3921570862734430208",
                label: "德阳市经济合作和外事局",
                value: "3921570862734430208",
            },
            {
                id: "3907865215912300544",
                label: "德阳市退役军人事务局",
                value: "3907865215912300544",
            },
            {
                id: "3906055481920114688",
                label: "纳税服务科",
                value: "3906055481920114688",
            },
            {
                id: "3906054441808089088",
                label: "纳税服务科",
                value: "3906054441808089088",
            },
            {
                id: "3903785947397935104",
                label: "经检大队",
                value: "3903785947397935104",
            },
            {
                id: "3890748361888681984",
                label: "德阳市工商业联合会",
                value: "3890748361888681984",
            },
            {
                id: "3879508482097729536",
                label: "德阳市新闻出版局",
                value: "3879508482097729536",
            },
            {
                id: "3867",
                label: "德阳市质量技术监督局经开区分局",
                value: "3867",
            },
            {
                id: "3855351988066242560",
                label: "德阳市群众工作中心",
                value: "3855351988066242560",
            },
            {
                id: "3835396841315684352",
                label: "德阳市农村能源办公室",
                value: "3835396841315684352",
            },
            {
                id: "3831051770089361408",
                label: "信贷科",
                value: "3831051770089361408",
            },
            {
                id: "3829166310114381824",
                label: "经济犯罪侦查支队",
                value: "3829166310114381824",
            },
            {
                id: "3997745659238338568",
                label: "四川省通信管理局德阳市通信发展办公室",
                value: "3997745659238338568",
            },
            {
                id: "4128460365333995520",
                label: "德阳市司法局律师工作科",
                value: "4128460365333995520",
            },
            {
                id: "4108281766966796288",
                label: "德阳市邮政管理局",
                value: "4108281766966796288",
            },
            {
                id: "4107845390609620992",
                label: "德阳市劳动能力鉴定中心",
                value: "4107845390609620992",
            },
            {
                id: "4106483362104025088",
                label: "德阳市教育局组干科",
                value: "4106483362104025088",
            },
            {
                id: "4106417158052061184",
                label: "德阳市社会保障信息中心",
                value: "4106417158052061184",
            },
            {
                id: "4106077126829350912",
                label: "德阳市自然资源和规划局旌阳分局",
                value: "4106077126829350912",
            },
            {
                id: "4100941044466917376",
                label: "德阳市消防救援支队",
                value: "4100941044466917376",
            },
            {
                id: "40936",
                label: "德阳市工商局开发区分局旌南工商所",
                value: "40936",
            },
            {
                id: "4091608377183248384",
                label: "质量技术执法工作组",
                value: "4091608377183248384",
            },
            {
                id: "4089",
                label: "德阳市食品药品监督管理局经济技术开发区分局",
                value: "4089",
            },
            {
                id: "4086107581016383488",
                label: "德阳市森林防火与植物检疫站",
                value: "4086107581016383488",
            },
            {
                id: "4065779145567838208",
                label: "德阳市医疗保障局",
                value: "4065779145567838208",
            },
            {
                id: "4060156523052212224",
                label: "德阳市市场监管局登记注册科",
                value: "4060156523052212224",
            },
            {
                id: "4034999984042041344",
                label: "德阳市测试部门（测试专用）",
                value: "4034999984042041344",
            },
            {
                id: "40163",
                label: "德阳市生产力促进中心",
                value: "40163",
            },
            {
                id: "3827312783222296576",
                label: "德阳市文化广播影视新闻出版局",
                value: "3827312783222296576",
            },
            {
                id: "3962309814088290304",
                label: "质监稽查分局",
                value: "3962309814088290304",
            },
            {
                id: "3962126600006455296",
                label: "食药稽查支队",
                value: "3962126600006455296",
            },
            {
                id: "3961521808238628864",
                label: "民营企业维权科",
                value: "3961521808238628864",
            },
            {
                id: "3961521292901535744",
                label: "民营经济综合协调科",
                value: "3961521292901535744",
            },
            {
                id: "3961520866651328512",
                label: "知识产权规划管理科",
                value: "3961520866651328512",
            },
            {
                id: "3961520665878384640",
                label: "科技与认证认可监督管理科",
                value: "3961520665878384640",
            },
            {
                id: "3961520473166893056",
                label: "标准化科",
                value: "3961520473166893056",
            },
            {
                id: "3961520295626330112",
                label: "计量科",
                value: "3961520295626330112",
            },
            {
                id: "3961520180789694464",
                label: "特种设备安全监察科",
                value: "3961520180789694464",
            },
            {
                id: "3961520002020724736",
                label: "化妆品监督管理科",
                value: "3961520002020724736",
            },
            {
                id: "3961519804087586816",
                label: "医疗器械监督管理科",
                value: "3961519804087586816",
            },
            {
                id: "3961519621320134656",
                label: "药品流通监督管理科",
                value: "3961519621320134656",
            },
            {
                id: "3961519438134431744",
                label: "药品监督管理与产业服务科",
                value: "3961519438134431744",
            },
            {
                id: "3961519242755100672",
                label: "食品安全抽检监测科",
                value: "3961519242755100672",
            },
            {
                id: "3323",
                label: "德阳市公安局交通警察支队",
                value: "3323",
            },
            {
                id: "3659",
                label: "专业技术人员管理科",
                value: "3659",
            },
            {
                id: "3557",
                label: "政府采购监督管理科",
                value: "3557",
            },
            {
                id: "3547",
                label: "德阳市水保办",
                value: "3547",
            },
            {
                id: "3534",
                label: "德阳市自然资源确权登记局",
                value: "3534",
            },
            {
                id: "353",
                label: "高新技术发展及产业化科",
                value: "353",
            },
            {
                id: "3492",
                label: "计量与认证科",
                value: "3492",
            },
            {
                id: "348",
                label: "财务规划科",
                value: "348",
            },
            {
                id: "3461",
                label: "德阳市不动产登记中心",
                value: "3461",
            },
            {
                id: "3348",
                label: "德阳市残疾人联合会",
                value: "3348",
            },
            {
                id: "33244",
                label: "交易监督科",
                value: "33244",
            },
            {
                id: "33243",
                label: "保证金管理科",
                value: "33243",
            },
            {
                id: "33241",
                label: "交易组织科",
                value: "33241",
            },
            {
                id: "33240",
                label: "交易服务科",
                value: "33240",
            },
            {
                id: "33239",
                label: "交易受理科",
                value: "33239",
            },
            {
                id: "33238",
                label: "综合科",
                value: "33238",
            },
            {
                id: "3686",
                label: "工伤保险科",
                value: "3686",
            },
            {
                id: "3285",
                label: "法规科",
                value: "3285",
            },
            {
                id: "32742",
                label: "督查考核科",
                value: "32742",
            },
            {
                id: "32172",
                label: "广电科技科",
                value: "32172",
            },
            {
                id: "32065",
                label: "综合执法支队",
                value: "32065",
            },
            {
                id: "3204",
                label: "德阳市建设工程招投标管理站",
                value: "3204",
            },
            {
                id: "31984",
                label: "综合秘书科",
                value: "31984",
            },
            {
                id: "3184",
                label: "行政审批服务科",
                value: "3184",
            },
            {
                id: "3169",
                label: "德阳市住房保障和房地产事务中心",
                value: "3169",
            },
            {
                id: "310",
                label: "德阳市司法局人民参与和促进法制科",
                value: "310",
            },
            {
                id: "3037",
                label: "德阳市水政监察支队",
                value: "3037",
            },
            {
                id: "30158",
                label: "德阳市市容环境卫生管理处",
                value: "30158",
            },
            {
                id: "3012",
                label: "德阳市环境监察支队",
                value: "3012",
            },
            {
                id: "29809",
                label: "德阳市城市管理行政执法局执法监督科",
                value: "29809",
            },
            {
                id: "2958",
                label: "城市建设管理科",
                value: "2958",
            },
            {
                id: "3809592055702147072",
                label: "德阳市公路路政管理处办公室",
                value: "3809592055702147072",
            },
            {
                id: "3827046139950714880",
                label: "德阳市人民防空办公室行政审批服务科",
                value: "3827046139950714880",
            },
            {
                id: "3827045237004619776",
                label: "德阳市人民防空办公室行政审批服务科",
                value: "3827045237004619776",
            },
            {
                id: "3827044751439220736",
                label: "德阳市人民防空办公室行政审批服务科",
                value: "3827044751439220736",
            },
            {
                id: "3827022267049889792",
                label: "文物科",
                value: "3827022267049889792",
            },
            {
                id: "3827011805574778880",
                label: "行政审批科",
                value: "3827011805574778880",
            },
            {
                id: "3826987916752769024",
                label: "德阳市图书馆",
                value: "3826987916752769024",
            },
            {
                id: "3826985937671081984",
                label: "德阳市文化馆",
                value: "3826985937671081984",
            },
            {
                id: "3826689041916153856",
                label: "德阳市民族宗教事务局",
                value: "3826689041916153856",
            },
            {
                id: "3818743296534040576",
                label: "纳税服务科",
                value: "3818743296534040576",
            },
            {
                id: "3816531537336389632",
                label: "国家税务总局德阳经济技术开发区税务局",
                value: "3816531537336389632",
            },
            {
                id: "3810016123643416576",
                label: "信息技术科",
                value: "3810016123643416576",
            },
            {
                id: "3810015889013919744",
                label: "行政审批科",
                value: "3810015889013919744",
            },
            {
                id: "3809949630881386496",
                label: "德阳市就业局",
                value: "3809949630881386496",
            },
            {
                id: "3809592511216537600",
                label: "德阳市公路路政管理治超大队",
                value: "3809592511216537600",
            },
            {
                id: "4134662670236459008",
                label: "德阳市法律援助中心",
                value: "4134662670236459008",
            },
            {
                id: "3809591393249316864",
                label: "德阳市公路路政管理处法纪监察室",
                value: "3809591393249316864",
            },
            {
                id: "3809590497707610112",
                label: "德阳市公路路政管理处巡查大队",
                value: "3809590497707610112",
            },
            {
                id: "3809588635066617856",
                label: "德阳市公路路政管理处案件处理中心",
                value: "3809588635066617856",
            },
            {
                id: "3808981812651544576",
                label: "德阳市质量技术监督局经开区分局",
                value: "3808981812651544576",
            },
            {
                id: "3808964776970342400",
                label: "价格管理科",
                value: "3808964776970342400",
            },
            {
                id: "3808964629226246144",
                label: "收费管理科",
                value: "3808964629226246144",
            },
            {
                id: "3808964412938571776",
                label: "行政审批科",
                value: "3808964412938571776",
            },
            {
                id: "3808963695968440320",
                label: "德阳市价格监督检查局",
                value: "3808963695968440320",
            },
            {
                id: "3807062952751190016",
                label: "德阳市食品药品监督稽查支队",
                value: "3807062952751190016",
            },
            {
                id: "3791171904246435840",
                label: "派驻纪检组",
                value: "3791171904246435840",
            },
            {
                id: "379",
                label: "德阳市教育局",
                value: "379",
            },
            {
                id: "3787184400056504320",
                label: "直属分局业务办2",
                value: "3787184400056504320",
            },
            {
                id: "3787098295080898560",
                label: "德阳市住房保障和房地产事务中心",
                value: "3787098295080898560",
            },
            {
                id: "371",
                label: "德阳市城市管理行政执法局",
                value: "371",
            },
            {
                id: "725",
                label: "自然资源调查检测科（测绘地理信息科）",
                value: "725",
            },
            {
                id: "8330",
                label: "行政审批服务科",
                value: "8330",
            },
            {
                id: "831",
                label: "知识产权规划发展科",
                value: "831",
            },
            {
                id: "8260",
                label: "行政审批科",
                value: "8260",
            },
            {
                id: "8236",
                label: "德阳市大学、中专招生委员会办公室",
                value: "8236",
            },
            {
                id: "8182",
                label: "德阳市老龄工作委员会办公室",
                value: "8182",
            },
            {
                id: "816",
                label: "德阳市生态环境局",
                value: "816",
            },
            {
                id: "802",
                label: "德阳市劳动监察支队",
                value: "802",
            },
            {
                id: "800",
                label: "养老保险科",
                value: "800",
            },
            {
                id: "796",
                label: "德阳市医疗保险局",
                value: "796",
            },
            {
                id: "7877",
                label: "德阳市粮食局政策法规科",
                value: "7877",
            },
            {
                id: "780",
                label: "出入境管理支队",
                value: "780",
            },
            {
                id: "7661",
                label: "办公室",
                value: "7661",
            },
            {
                id: "728",
                label: "就业促进与失业保险科",
                value: "728",
            },
            {
                id: "726",
                label: "德阳市审计局财政审计科",
                value: "726",
            },
            {
                id: "7254",
                label: "德阳市国有资产管理委员会",
                value: "7254",
            },
            {
                id: "8347",
                label: "接收保管科",
                value: "8347",
            },
            {
                id: "7244",
                label: "德阳市产业促进服务中心",
                value: "7244",
            },
            {
                id: "7224",
                label: "招标投标管理科",
                value: "7224",
            },
            {
                id: "722",
                label: "德阳市政务服务和大数据管理局",
                value: "722",
            },
            {
                id: "7160",
                label: "民防应急科",
                value: "7160",
            },
            {
                id: "7151",
                label: "德阳市林业局办公室",
                value: "7151",
            },
            {
                id: "713",
                label: "消费者权益保护科",
                value: "713",
            },
            {
                id: "7118",
                label: "德阳市森林植物检疫站",
                value: "7118",
            },
            {
                id: "7089",
                label: "特种设备安全监察科",
                value: "7089",
            },
            {
                id: "7062",
                label: "招标办市场稽查科",
                value: "7062",
            },
            {
                id: "7032",
                label: "德阳市工商局直属分局城北工商所",
                value: "7032",
            },
            {
                id: "697",
                label: "科技设计科",
                value: "697",
            },
            {
                id: "6785",
                label: "德阳市水务局行政审批科",
                value: "6785",
            },
            {
                id: "6747",
                label: "办公室",
                value: "6747",
            },
            {
                id: "669",
                label: "德阳市人民政府办公室",
                value: "669",
            },
            {
                id: "903",
                label: "德阳市粮食局监督检查科",
                value: "903",
            },
            {
                id: "101",
                label: "市科技和知识产权稽查支队",
                value: "101",
                children: [
                    {
                        id: "5A588DEF35",
                        label: "测试部门",
                        value: "5A588DEF35",
                    },
                ],
            },
            {
                id: "997",
                label: "德阳市文化广播电视和旅游局",
                value: "997",
            },
            {
                id: "992",
                label: "市局鉴定科",
                value: "992",
            },
            {
                id: "985",
                label: "德阳市工商局直属分局",
                value: "985",
            },
            {
                id: "984",
                label: "政策法规宣传科",
                value: "984",
            },
            {
                id: "983",
                label: "禁毒支队",
                value: "983",
            },
            {
                id: "972",
                label: "德阳市市场监管局网络交易监督管理科",
                value: "972",
            },
            {
                id: "9627",
                label: "药品化妆品市场监督管理科",
                value: "9627",
            },
            {
                id: "9521",
                label: "德阳市食品药品监督管理局行政审批科",
                value: "9521",
            },
            {
                id: "9501",
                label: "德阳市委网信办",
                value: "9501",
            },
            {
                id: "944",
                label: "标准化科",
                value: "944",
            },
            {
                id: "943",
                label: "德阳市旅游局",
                value: "943",
            },
            {
                id: "942",
                label: "德阳市审计局各业务科（局）",
                value: "942",
            },
            {
                id: "936",
                label: "收费管理科",
                value: "936",
            },
            {
                id: "6661",
                label: "四川九顶山省级自然保护区管理处",
                value: "6661",
            },
            {
                id: "901",
                label: "财务和基金监督科",
                value: "901",
            },
            {
                id: "891",
                label: "德阳市农业农村局",
                value: "891",
            },
            {
                id: "8841",
                label: "德阳市食品药品监督管理局监察室",
                value: "8841",
            },
            {
                id: "8811",
                label: "德阳市食品药品监督管理局法规科",
                value: "8811",
            },
            {
                id: "8791",
                label: "职业安全健康监督管理科",
                value: "8791",
            },
            {
                id: "8707",
                label: "食品流通监督管理科",
                value: "8707",
            },
            {
                id: "8621",
                label: "中国银行业监督管理委员会德阳分局",
                value: "8621",
            },
            {
                id: "8616",
                label: "餐饮服务监督管理科",
                value: "8616",
            },
            {
                id: "8586",
                label: "德阳市扶贫和移民工作局",
                value: "8586",
            },
            {
                id: "846",
                label: "市法制办主审分派部门",
                value: "846",
            },
            {
                id: "844",
                label: "科技宣传与普及科",
                value: "844",
            },
            {
                id: "8434",
                label: "德阳市供销合作社联合社办公室",
                value: "8434",
            },
            {
                id: "8411",
                label: "安全生产监察执法支队",
                value: "8411",
            },
            {
                id: "839",
                label: "科技与信息科",
                value: "839",
            },
            {
                id: "443",
                label: "市体育局",
                value: "443",
            },
            {
                id: "5556",
                label: "综合科",
                value: "5556",
            },
            {
                id: "5549",
                label: "德阳市市生态修复与地质灾害防治所",
                value: "5549",
            },
            {
                id: "5452",
                label: "监察室",
                value: "5452",
            },
            {
                id: "5433",
                label: "德阳市特种设备监督检验所",
                value: "5433",
            },
            {
                id: "542",
                label: "招标办招标科",
                value: "542",
            },
            {
                id: "532",
                label: "德阳市防震减灾局",
                value: "532",
            },
            {
                id: "5319",
                label: "德阳市河湖管理保护科",
                value: "5319",
            },
            {
                id: "5279",
                label: "德阳市保险行业协会",
                value: "5279",
            },
            {
                id: "514",
                label: "住建局局领导",
                value: "514",
            },
            {
                id: "5057",
                label: "德阳市食品药品监督稽查支队",
                value: "5057",
            },
            {
                id: "5036",
                label: "行政审批科",
                value: "5036",
            },
            {
                id: "479",
                label: "德阳市供水办",
                value: "479",
            },
            {
                id: "4677",
                label: "德阳市森林资源管理站",
                value: "4677",
            },
            {
                id: "459",
                label: "直属分局业务办",
                value: "459",
            },
            {
                id: "5575",
                label:
                    "中国人民银行德阳市中心支行（国家外汇管理局德阳市中心支局）",
                value: "5575",
            },
            {
                id: "4423",
                label: "德阳国家安全局",
                value: "4423",
            },
            {
                id: "43774",
                label: "德阳市粮食局财会审计科",
                value: "43774",
            },
            {
                id: "433",
                label: "德阳市住房和城乡建设局",
                value: "433",
            },
            {
                id: "422",
                label: "市文广旅局局领导",
                value: "422",
            },
            {
                id: "4194523486980612096",
                label: "知识产权执法组",
                value: "4194523486980612096",
            },
            {
                id: "4194523340167389184",
                label: "双反组",
                value: "4194523340167389184",
            },
            {
                id: "4194522984641536000",
                label: "价格执法组",
                value: "4194522984641536000",
            },
            {
                id: "4194522881872568320",
                label: "食品执法组",
                value: "4194522881872568320",
            },
            {
                id: "4194522752985800704",
                label: "药品执法组",
                value: "4194522752985800704",
            },
            {
                id: "4194417269583060992",
                label: "中共德阳市委网络安全和信息化委员会办公室",
                value: "4194417269583060992",
            },
            {
                id: "4190071259830493184",
                label: "德阳市司法局区域协调科",
                value: "4190071259830493184",
            },
            {
                id: "4186883935349055488",
                label: "中共德阳市委保密机要局",
                value: "4186883935349055488",
            },
            {
                id: "4184733044256874496",
                label: "四川省烟草公司德阳市公司",
                value: "4184733044256874496",
            },
            {
                id: "418",
                label: "德阳市盐政市场稽查处",
                value: "418",
            },
            {
                id: "60399",
                label: "德阳市司法局普法与依法治理科",
                value: "60399",
            },
            {
                id: "665",
                label: "德阳市科学技术局",
                value: "665",
            },
            {
                id: "6641",
                label: "法制处",
                value: "6641",
            },
            {
                id: "659",
                label: "德阳市财政局",
                value: "659",
            },
            {
                id: "6580",
                label: "德阳市水资源管理科",
                value: "6580",
            },
            {
                id: "6460",
                label: "经济发展科",
                value: "6460",
            },
            {
                id: "614",
                label: "德阳市体育局",
                value: "614",
            },
            {
                id: "6130",
                label: "法制科",
                value: "6130",
            },
            {
                id: "6129",
                label: "德阳市民政局",
                value: "6129",
            },
            {
                id: "61054",
                label: "文艺科",
                value: "61054",
            },
            {
                id: "6068",
                label: "德阳市野生动植物管理站",
                value: "6068",
            },
            {
                id: "60675",
                label: "警种3",
                value: "60675",
            },
            {
                id: "60653",
                label: "警种1，误删除公安厅一体化项目创建账号",
                value: "60653",
            },
            {
                id: "60652",
                label: "警种2",
                value: "60652",
            },
            {
                id: "60439",
                label: "房地产业管理科",
                value: "60439",
            },
            {
                id: "2950",
                label: "原工商经检支队",
                value: "2950",
            },
            {
                id: "60377",
                label: "德阳市教育局督导室",
                value: "60377",
            },
            {
                id: "60343",
                label: "德阳市粮食局调控科",
                value: "60343",
            },
            {
                id: "595",
                label: "地质矿产监督管理科",
                value: "595",
            },
            {
                id: "5942",
                label: "德阳市邮政管理局",
                value: "5942",
            },
            {
                id: "590",
                label: "德阳市审计局领导",
                value: "590",
            },
            {
                id: "5893",
                label: "无线电监督管理科",
                value: "5893",
            },
            {
                id: "588",
                label: "城南工商所法制员",
                value: "588",
            },
            {
                id: "586",
                label: "德阳市地方志办公室",
                value: "586",
            },
            {
                id: "5827",
                label: "德阳市机关事务管理局",
                value: "5827",
            },
            {
                id: "5793",
                label: "合作指导科",
                value: "5793",
            },
            {
                id: "5756",
                label: "经济运行科",
                value: "5756",
            },
            {
                id: "5727",
                label: "德阳市造林种苗站（德阳市中心苗圃）",
                value: "5727",
            },
            {
                id: "5720",
                label: "德阳市公路工程质量监督分站",
                value: "5720",
            },
            {
                id: "5611",
                label: "建筑安全管理科",
                value: "5611",
            },
            {
                id: "1548",
                label: "德阳市地方海事局",
                value: "1548",
            },
            {
                id: "1658",
                label: "德阳市司法局公共法律服务科",
                value: "1658",
            },
            {
                id: "1651",
                label: "德阳市发展和改革委员会",
                value: "1651",
            },
            {
                id: "1637",
                label: "德阳市市场监督管理局",
                value: "1637",
            },
            {
                id: "1634",
                label: "德阳市社会保险事务中心",
                value: "1634",
            },
            {
                id: "1618",
                label: "工资福利科",
                value: "1618",
            },
            {
                id: "1616",
                label: "德阳市侨务办公室",
                value: "1616",
            },
            {
                id: "1610",
                label: "德阳市审计局政工人事科",
                value: "1610",
            },
            {
                id: "1606",
                label: "德阳市人事考试中心",
                value: "1606",
            },
            {
                id: "16052",
                label: "环境犯罪侦查支队",
                value: "16052",
            },
            {
                id: "1603",
                label: "科技信息研究所",
                value: "1603",
            },
            {
                id: "1580",
                label: "市志办法规管理员",
                value: "1580",
            },
            {
                id: "1567",
                label: "德阳市工商局开发区分局旌湖工商所",
                value: "1567",
            },
            {
                id: "1564",
                label: "德阳市供水中心",
                value: "1564",
            },
            {
                id: "15602",
                label: "网络信息管理科",
                value: "15602",
            },
            {
                id: "1556",
                label: "德阳市工商局法制科",
                value: "1556",
            },
            {
                id: "1666",
                label: "德阳市司法局",
                value: "1666",
            },
            {
                id: "15380",
                label: "德阳市城市管理行政执法支队",
                value: "15380",
            },
            {
                id: "1517",
                label: "德阳市市场监管局经济技术开发区分局",
                value: "1517",
            },
            {
                id: "1510",
                label: "德阳市审计局政策法规科",
                value: "1510",
            },
            {
                id: "1496",
                label: "科技开发交流中心",
                value: "1496",
            },
            {
                id: "1479",
                label: "网监支队",
                value: "1479",
            },
            {
                id: "1470",
                label: "科技成果科",
                value: "1470",
            },
            {
                id: "1464",
                label: "消防支队",
                value: "1464",
            },
            {
                id: "146",
                label: "德阳市国家保密局",
                value: "146",
            },
            {
                id: "144",
                label: "德阳市审计局办公室",
                value: "144",
            },
            {
                id: "14378",
                label: "并联审批科",
                value: "14378",
            },
            {
                id: "1437",
                label: "人事科",
                value: "1437",
            },
            {
                id: "14190",
                label: "企业安全监督管理科",
                value: "14190",
            },
            {
                id: "1419",
                label: "市法制办主审部门",
                value: "1419",
            },
            {
                id: "1414",
                label: "德阳市地方海事局办公室",
                value: "1414",
            },
            {
                id: "182",
                label: "市局旌东分局",
                value: "182",
            },
            {
                id: "1950",
                label: "法规科",
                value: "1950",
            },
            {
                id: "1943",
                label: "招标办综合科",
                value: "1943",
            },
            {
                id: "1938",
                label: "耕地保护监督科",
                value: "1938",
            },
            {
                id: "1928",
                label: "德阳市经济和信息化局",
                value: "1928",
            },
            {
                id: "1917",
                label: "德阳市住房公积金管理中心",
                value: "1917",
            },
            {
                id: "1915",
                label: "建筑行业管理科",
                value: "1915",
            },
            {
                id: "1907",
                label: "开发区局业务办",
                value: "1907",
            },
            {
                id: "18933",
                label: "德阳市食品药品监督管理局风险监测科",
                value: "18933",
            },
            {
                id: "1882",
                label: "开发区分局",
                value: "1882",
            },
            {
                id: "1877",
                label: "行政审批服务科",
                value: "1877",
            },
            {
                id: "1875",
                label: "德阳市质量技术监督局",
                value: "1875",
            },
            {
                id: "18456",
                label: "政策法规科",
                value: "18456",
            },
            {
                id: "1830",
                label: "德阳市工商局开发区分局经检大队",
                value: "1830",
            },
            {
                id: "1827",
                label: "德阳市水务局建管科",
                value: "1827",
            },
            {
                id: "1413",
                label: "德阳市民族宗教事务局",
                value: "1413",
            },
            {
                id: "18043",
                label: "法规宣传培训科",
                value: "18043",
            },
            {
                id: "1800",
                label: "档案指导科",
                value: "1800",
            },
            {
                id: "1797",
                label: "德阳市政府法制办公室",
                value: "1797",
            },
            {
                id: "17949",
                label: "纪检监察室",
                value: "17949",
            },
            {
                id: "1781",
                label: "德阳市商务局",
                value: "1781",
            },
            {
                id: "1772",
                label: "建筑业管理科",
                value: "1772",
            },
            {
                id: "1771",
                label: "职业能力建设科",
                value: "1771",
            },
            {
                id: "1767",
                label: "德阳市水务局政策法规科",
                value: "1767",
            },
            {
                id: "174",
                label: "德阳市卫生和计划生育监督执法支队",
                value: "174",
            },
            {
                id: "1738",
                label: "监督检查分局",
                value: "1738",
            },
            {
                id: "1700",
                label: "稽查分局",
                value: "1700",
            },
            {
                id: "1698",
                label: "德阳市城市建设档案馆",
                value: "1698",
            },
            {
                id: "16796",
                label: "反恐怖工作支队",
                value: "16796",
            },
            {
                id: "1668",
                label: "八角工商所法制员",
                value: "1668",
            },
            {
                id: "1115",
                label: "市局法律援助中心",
                value: "1115",
            },
            {
                id: "12748",
                label: "德阳市金融工作局",
                value: "12748",
            },
            {
                id: "1271",
                label: "绿色材料推广站",
                value: "1271",
            },
            {
                id: "12597",
                label: "德阳市工商局个体科",
                value: "12597",
            },
            {
                id: "12568",
                label: "办公室",
                value: "12568",
            },
            {
                id: "1229",
                label: "德阳市监察局",
                value: "1229",
            },
            {
                id: "1228",
                label: "企业科",
                value: "1228",
            },
            {
                id: "12249",
                label: "价格收费科",
                value: "12249",
            },
            {
                id: "1217",
                label: "市商务局办公室",
                value: "1217",
            },
            {
                id: "1191",
                label: "劳动关系科",
                value: "1191",
            },
            {
                id: "1174",
                label: "德阳市粮食局仓储管理科",
                value: "1174",
            },
            {
                id: "1166",
                label: "德阳市国有土地上房屋征收与补偿中心",
                value: "1166",
            },
            {
                id: "1154",
                label: "德阳市档案局",
                value: "1154",
            },
            {
                id: "11187",
                label: "矿山安全监督管理科",
                value: "11187",
            },
            {
                id: "11173",
                label: "医疗器械监督管理科",
                value: "11173",
            },
            {
                id: "1116",
                label: "四川省烟草公司德阳市公司",
                value: "1116",
            },
            {
                id: "128",
                label: "价格监督检查局",
                value: "128",
            },
            {
                id: "111",
                label: "德阳市水利地方电力建设管理处",
                value: "111",
            },
            {
                id: "11089",
                label: "招标投标科",
                value: "11089",
            },
            {
                id: "1102",
                label: "德阳市应急管理局",
                value: "1102",
            },
            {
                id: "1097",
                label: "矿管科",
                value: "1097",
            },
            {
                id: "1095",
                label: "德阳市森林公安局",
                value: "1095",
            },
            {
                id: "1089",
                label: "政策法规宣传培训科",
                value: "1089",
            },
            {
                id: "1079",
                label: "市志办监察管理员",
                value: "1079",
            },
            {
                id: "1073",
                label: "德阳市气象局",
                value: "1073",
            },
            {
                id: "10597",
                label: "中华人民共和国德阳出入境检验检疫局",
                value: "10597",
            },
            {
                id: "10547",
                label: "药品化妆品生产监督管理科",
                value: "10547",
            },
            {
                id: "1044",
                label: "德阳市房管局中介科",
                value: "1044",
            },
            {
                id: "10439",
                label: "德阳市食品药品监督管理局食品生产监督管理科",
                value: "10439",
            },
            {
                id: "1035",
                label: "市局法制科",
                value: "1035",
            },
            {
                id: "1028",
                label: "德阳市工商局直属分局城南工商所",
                value: "1028",
            },
            {
                id: "13208",
                label: "国家统计局德阳调查队",
                value: "13208",
            },
            {
                id: "14020",
                label: "德阳市价格监测中心",
                value: "14020",
            },
            {
                id: "1400",
                label: "德阳市自然资源和规划局",
                value: "1400",
            },
            {
                id: "13918",
                label: "资源节约与环境保护科",
                value: "13918",
            },
            {
                id: "13901",
                label: "德阳市经济研究中心",
                value: "13901",
            },
            {
                id: "13880",
                label: "危险化学品管理科",
                value: "13880",
            },
            {
                id: "13871",
                label: "德阳市价格认证中心",
                value: "13871",
            },
            {
                id: "13821",
                label: "用地科",
                value: "13821",
            },
            {
                id: "13610",
                label: "德阳海关",
                value: "13610",
            },
            {
                id: "13570",
                label: "纪检监察室",
                value: "13570",
            },
            {
                id: "13434",
                label: "业务指导科",
                value: "13434",
            },
            {
                id: "13421",
                label: "利用编研科",
                value: "13421",
            },
            {
                id: "13396",
                label: "德阳市引进外资项目办公室",
                value: "13396",
            },
            {
                id: "1332",
                label: "信用监督管理科",
                value: "1332",
            },
            {
                id: "1331",
                label: "广告监督管理科",
                value: "1331",
            },
            {
                id: "1951",
                label: "国土空间规划局",
                value: "1951",
            },
            {
                id: "1320",
                label: "社区矫正管理支队",
                value: "1320",
            },
            {
                id: "13159",
                label: "价格管理科",
                value: "13159",
            },
            {
                id: "13133",
                label: "政策法规科",
                value: "13133",
            },
            {
                id: "1313",
                label: "德阳市土地矿权交易中心",
                value: "1313",
            },
            {
                id: "13085",
                label: "法规与行政审批科",
                value: "13085",
            },
            {
                id: "1303",
                label: "城北工商所法制员",
                value: "1303",
            },
            {
                id: "12997",
                label: "消委秘书科",
                value: "12997",
            },
            {
                id: "12989",
                label: "国土空间用途管制科",
                value: "12989",
            },
            {
                id: "1297",
                label: "德阳市林业局",
                value: "1297",
            },
            {
                id: "1295",
                label: "德阳市总工会",
                value: "1295",
            },
            {
                id: "1291",
                label: "德阳市公安局",
                value: "1291",
            },
            {
                id: "1287",
                label: "德阳市工商局市场科",
                value: "1287",
            },
            {
                id: "1285",
                label: "农机监理所",
                value: "1285",
            },
            {
                id: "1284",
                label: "德阳市工商局直属分局旌阳工商所",
                value: "1284",
            },
            {
                id: "26180",
                label: "社会事业科",
                value: "26180",
            },
            {
                id: "26932",
                label: "12315指挥中心",
                value: "26932",
            },
            {
                id: "26915",
                label: "德阳市教育局职成高教科",
                value: "26915",
            },
            {
                id: "2689",
                label: "建设工程造价管理站",
                value: "2689",
            },
            {
                id: "26876",
                label: "德阳市住房公积金管理中心绵竹管理部",
                value: "26876",
            },
            {
                id: "26828",
                label: "德阳市教育局办公室",
                value: "26828",
            },
            {
                id: "26812",
                label: "市体育局社体科",
                value: "26812",
            },
            {
                id: "26754",
                label: "德阳市能源监察中心",
                value: "26754",
            },
            {
                id: "2660",
                label: "勘察设计管理科",
                value: "2660",
            },
            {
                id: "26505",
                label: "民间组织管理科",
                value: "26505",
            },
            {
                id: "26419",
                label: "市体育局政工科",
                value: "26419",
            },
            {
                id: "26397",
                label: "德阳市教育后勤管理中心",
                value: "26397",
            },
            {
                id: "26392",
                label: "政工人事培训科",
                value: "26392",
            },
            {
                id: "2629",
                label: "德阳市粮食局",
                value: "2629",
            },
            {
                id: "26230",
                label: "无线电监测站",
                value: "26230",
            },
            {
                id: "26226",
                label: "德阳市教育局纪工委",
                value: "26226",
            },
            {
                id: "270",
                label: "德阳市食品药品监督管理局",
                value: "270",
            },
            {
                id: "26079",
                label: "监察室",
                value: "26079",
            },
            {
                id: "25977",
                label: "德阳市教育局行政审批科",
                value: "25977",
            },
            {
                id: "25960",
                label: "德阳市城市管理行政执法局综合管理科",
                value: "25960",
            },
            {
                id: "25759",
                label: "德阳市园林管理局（德阳市风景园林科研所）",
                value: "25759",
            },
            {
                id: "25732",
                label: "体育局办公室",
                value: "25732",
            },
            {
                id: "25687",
                label: "农村经济科",
                value: "25687",
            },
            {
                id: "2568",
                label: "德阳市公用事业中心",
                value: "2568",
            },
            {
                id: "25667",
                label: "政策法规和体制改革科",
                value: "25667",
            },
            {
                id: "25586",
                label: "德阳市城市管理行政执法局监察室",
                value: "25586",
            },
            {
                id: "25491",
                label: "德阳市重大项目推进中心",
                value: "25491",
            },
            {
                id: "25362",
                label: "德阳市城市综合管理指挥中心",
                value: "25362",
            },
            {
                id: "25338",
                label: "德阳市城市管理行政执法局政策法规科",
                value: "25338",
            },
            {
                id: "25306",
                label: "政策法规科",
                value: "25306",
            },
            {
                id: "25257",
                label: "德阳市公路工程质量监督分站",
                value: "25257",
            },
            {
                id: "27688",
                label: "德阳市强制隔离戒毒所",
                value: "27688",
            },
            {
                id: "294",
                label: "生态保护修复科",
                value: "294",
            },
            {
                id: "2921",
                label: "建设工程规划管理科",
                value: "2921",
            },
            {
                id: "2903",
                label: "德阳市就业创业促进中心",
                value: "2903",
            },
            {
                id: "288",
                label: "发展计划和财务科",
                value: "288",
            },
            {
                id: "286",
                label: "德阳市审计局",
                value: "286",
            },
            {
                id: "2857",
                label: "行政审批科",
                value: "2857",
            },
            {
                id: "28551",
                label: "医药食品发展办公室",
                value: "28551",
            },
            {
                id: "2844",
                label: "德阳市水利工程建设质量与安全监督站",
                value: "2844",
            },
            {
                id: "2841",
                label: "德阳市司法局行政审批科",
                value: "2841",
            },
            {
                id: "284",
                label: "政策法规信访科",
                value: "284",
            },
            {
                id: "28357",
                label: "能源发展科",
                value: "28357",
            },
            {
                id: "28047",
                label: "国保支队",
                value: "28047",
            },
            {
                id: "2802",
                label: "监察室",
                value: "2802",
            },
            {
                id: "277",
                label: "旌阳工商所法制员",
                value: "277",
            },
            {
                id: "25204",
                label: "德阳市住房公积金管理中心罗江管理部",
                value: "25204",
            },
            {
                id: "27560",
                label: "德阳市住房公积金管理中心旌阳管理部",
                value: "27560",
            },
            {
                id: "27551",
                label: "市体育局竞训科",
                value: "27551",
            },
            {
                id: "27512",
                label: "德阳市住房公积金管理中心中江管理部",
                value: "27512",
            },
            {
                id: "27499",
                label: "财贸外资外经科",
                value: "27499",
            },
            {
                id: "27488",
                label: "德阳市食品药品监督管理局综合科",
                value: "27488",
            },
            {
                id: "2734",
                label: "行政审批科",
                value: "2734",
            },
            {
                id: "27329",
                label: "德阳市城市管理行政执法局行政审批科",
                value: "27329",
            },
            {
                id: "27300",
                label: "德阳市地方海事局",
                value: "27300",
            },
            {
                id: "27283",
                label: "行政审批科",
                value: "27283",
            },
            {
                id: "27214",
                label: "德阳市教育局人事科",
                value: "27214",
            },
            {
                id: "2718",
                label: "德阳市旌湖水闸管理处",
                value: "2718",
            },
            {
                id: "2717",
                label: "自然资源开发利用科 （自然资源所有者权益科）",
                value: "2717",
            },
            {
                id: "27164",
                label: "德阳市教育局基教科",
                value: "27164",
            },
            {
                id: "27149",
                label: "优抚安置科",
                value: "27149",
            },
            {
                id: "2136",
                label: "德阳市人民防空办公室",
                value: "2136",
            },
            {
                id: "2273",
                label: "德阳市水务局监察室",
                value: "2273",
            },
            {
                id: "22588",
                label: "技术改造与创新科",
                value: "22588",
            },
            {
                id: "2258",
                label: "德阳市防汛抗旱指挥部办公室",
                value: "2258",
            },
            {
                id: "2247",
                label: "质量监督科",
                value: "2247",
            },
            {
                id: "22258",
                label: "军民融合促进科",
                value: "22258",
            },
            {
                id: "222",
                label: "八角工商所",
                value: "222",
            },
            {
                id: "22104",
                label: "行政审批服务科",
                value: "22104",
            },
            {
                id: "2170",
                label: "德阳市工商局直属分局经检大队",
                value: "2170",
            },
            {
                id: "2166",
                label: "国家税务总局德阳市税务局",
                value: "2166",
            },
            {
                id: "2163",
                label: "德阳市产品质量监督检验所",
                value: "2163",
            },
            {
                id: "2155",
                label: "污染防治科",
                value: "2155",
            },
            {
                id: "2150",
                label: "四川省德阳市人民检察院",
                value: "2150",
            },
            {
                id: "215",
                label: "德阳市人力资源服务中心",
                value: "215",
            },
            {
                id: "2140",
                label: "财税法规会计科",
                value: "2140",
            },
            {
                id: "21396",
                label: "德阳市公路路政管理处",
                value: "21396",
            },
            {
                id: "2280",
                label: "德阳市计量测试所",
                value: "2280",
            },
            {
                id: "21334",
                label: "能源监察中心",
                value: "21334",
            },
            {
                id: "2129",
                label: "监察室",
                value: "2129",
            },
            {
                id: "2080",
                label: "质量管理科",
                value: "2080",
            },
            {
                id: "2073",
                label: "德阳市林业科学技术推广站",
                value: "2073",
            },
            {
                id: "20477",
                label: "消防救援支队",
                value: "20477",
            },
            {
                id: "2046",
                label: "德阳市水利局",
                value: "2046",
            },
            {
                id: "2033",
                label: "德阳市人力资源和社会保障局",
                value: "2033",
            },
            {
                id: "2018",
                label: "德阳市统计局",
                value: "2018",
            },
            {
                id: "20144",
                label: "纪检监察室",
                value: "20144",
            },
            {
                id: "2014",
                label: "德阳市供销合作社联合社",
                value: "2014",
            },
            {
                id: "20138",
                label: "德阳市城乡规划管理局",
                value: "20138",
            },
            {
                id: "1999",
                label: "办公室",
                value: "1999",
            },
            {
                id: "1990",
                label: "德阳市文化旅游市场综合行政执法支队",
                value: "1990",
            },
            {
                id: "1962",
                label: "行政审批支队",
                value: "1962",
            },
            {
                id: "24610",
                label: "德阳市市政工程维护管理处",
                value: "24610",
            },
            {
                id: "25175",
                label: "德阳市城市照明设施管理处",
                value: "25175",
            },
            {
                id: "2516",
                label: "治安支队",
                value: "2516",
            },
            {
                id: "2515",
                label: "综合科",
                value: "2515",
            },
            {
                id: "25146",
                label: "德阳市语言文字工作委员会办公室",
                value: "25146",
            },
            {
                id: "25144",
                label: "德阳市体育局办公室",
                value: "25144",
            },
            {
                id: "25125",
                label: "社会福利和慈善促进科",
                value: "25125",
            },
            {
                id: "25111",
                label: "德阳市公园管理处",
                value: "25111",
            },
            {
                id: "25070",
                label: "监察室",
                value: "25070",
            },
            {
                id: "25063",
                label: "办公室",
                value: "25063",
            },
            {
                id: "24954",
                label: "德阳市住房公积金管理中心广汉管理部",
                value: "24954",
            },
            {
                id: "24879",
                label: "社会事务和区划地名科",
                value: "24879",
            },
            {
                id: "248",
                label: "德阳市交通运输局",
                value: "248",
            },
            {
                id: "24630",
                label: "德阳市住房公积金管理中心什邡管理部",
                value: "24630",
            },
            {
                id: "2463",
                label: "市科知局纪检监察",
                value: "2463",
            },
            {
                id: "1023",
                label: "德阳市司法局律师工作科",
                value: "1023",
            },
            {
                id: "24469",
                label: "德阳市教育资助中心",
                value: "24469",
            },
            {
                id: "24439",
                label: "德阳市教育局法规科",
                value: "24439",
            },
            {
                id: "24374",
                label: "德阳市道路运输管理处",
                value: "24374",
            },
            {
                id: "24333",
                label: "客户服务科",
                value: "24333",
            },
            {
                id: "243",
                label: "德阳市商务局事项填报",
                value: "243",
            },
            {
                id: "2392",
                label: "建设工程质量安全监督站",
                value: "2392",
            },
            {
                id: "2388",
                label: "工程建设管理科",
                value: "2388",
            },
            {
                id: "2338",
                label: "德阳市纤维检验所",
                value: "2338",
            },
            {
                id: "233",
                label: "德阳市工商局监察室",
                value: "233",
            },
            {
                id: "2323",
                label: "德阳市公共资源交易中心",
                value: "2323",
            },
            {
                id: "2322",
                label: "德阳市建设监察支队",
                value: "2322",
            },
            {
                id: "2314",
                label: "登记注册科",
                value: "2314",
            },
            {
                id: "2308",
                label: "德阳市自然资源综合行政执法支队",
                value: "2308",
            },
            {
                id: "22817",
                label: "德阳市民政局办公室",
                value: "22817",
            },
        ],
    },
    {
        id: "C3567F1A1D",
        label: "德阳测试部门",
        value: "C3567F1A1D",
    },
];